import React, { useState, useEffect, useRef } from "react";
import './Header.css';
import { ReactComponent as Logo } from "./assets/logo.svg"
import { ReactComponent as Menu } from "./assets/menu.svg"
import { Link } from 'react-router-dom';
import {
  useParams,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { withRouter } from 'react-router';

const Header = withRouter(props =>{
  const selectClassName = "header-select"
  const [toggleClassName, setToggleClassName] = useState("toggle-off");
  const [toggle, setToggle] = useState(false);
  const changeToggle = () => {
    if (toggle){
      setToggle(false);
      setToggleClassName("menu--toggle-off");
    }else{
      setToggle(true);
      setToggleClassName("menu--toggle");
    }
  };

  function handlePageMove(link) {
    props.history.push(link);
    changeToggle();
  }

  return (
    <div className="header-wrapper">
      <div className="header-container">
          <div className="logo-container">
            <Link to='/'>
              <Logo />
            </Link>
            <span className="logo-sub">
              フリーBGM<br/>無料ダウンロード
            </span>
          </div>
          <nav className="nav-container">
            <ul className="nav-list">
              <li className={((useLocation().pathname.match("/m/")) || (useLocation().pathname == "/") || (useLocation().pathname.match("/c/"))) ? selectClassName : ""}>
                <Link to='/'>
                フリーBGM
                </Link>
              </li>
              <li className={useLocation().pathname == "/about" ? selectClassName : ""}>
                <Link to='/about'>
                サイト概要
                </Link>
              </li>
              <li className={useLocation().pathname == "/terms" ? selectClassName : ""}>
                <Link to='/terms'>
                利用規約
                </Link>
              </li>
              <li className={useLocation().pathname == "/inquiry" ? selectClassName : ""}>
                <Link to='/inquiry'>
                お問い合わせ
                </Link>
              </li>
              <li>
              <a href="https://www.youtube.com/channel/UC3VHN3HnMH5bCKkAFoMMdjA" target="_blank">
              YouTube
              </a>  
              </li>
            </ul>
          </nav>
          <div className="hamburger">
          <button
            type="button"
            className="hamburger-menu-btn"
            aria-label="hamburger-btn"
            onClick={() => changeToggle()}
          >
            <Menu />
          </button>

          </div>
        </div>
        <nav className={"nav-container-hamburger " + toggleClassName}>
          <div className="close-container">
          <button
            type="button"
            className="hamburger-close-btn"
            aria-label="hamburger-btn"
            onClick={() => changeToggle()}
          >
            <div className="close">
            </div>
          </button>
          </div>
          <ul className="nav-list-hamburger">
            <li onClick={() => handlePageMove("/")}>
              フリーBGM
            </li>
            <li onClick={() => handlePageMove("/about")}>
              サイト概要
            </li>
            <li onClick={() => handlePageMove("/terms")}>
              利用規約
            </li>
            <li onClick={() => handlePageMove("/inquiry")}>
              お問い合わせ
            </li>
            <li>
              <a href="https://www.youtube.com/channel/UC3VHN3HnMH5bCKkAFoMMdjA" target="_blank">
              YouTube
              </a>  
            </li>
          </ul>
        </nav>
    </div>
    );
})
;

export default Header;
