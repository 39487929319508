import './Inquiry.css';
import React, { useState, useEffect, useRef } from "react";
import Header from "./Header";
import Footer from "./Footer";
import emailjs from '@emailjs/browser';
import { Helmet } from 'react-helmet';
import headData from "./head-data.json";
import { NavLink } from 'react-router-dom';

const Inquiry = ({
}) => {

const [name, setName] = useState("");
const [email, setEmail] = useState("");
const [inquiryContents, setInquiryContents] = useState("");
const [isSuccess, setIsSuccess] = useState(false);

const inputName = (value) => {
  setName(value);
}

const inputEmail = (value) => {
  setEmail(value);
}

const inputinquiryContents = (value) => {
  setInquiryContents(value);
}

const form = useRef();

const sendEmail = (e) => {
  e.preventDefault();

  emailjs.sendForm('service_wgw17rd', 'template_3dl8rgy', form.current, 'JWMrHQWTFHIK503nG')
    .then((result) => {
        console.log(result.text);
        setIsSuccess(true);
    }, (error) => {
        console.log(error.text);
    });
};


return (
  <React.StrictMode>
    <Helmet>
    <title>{headData.inquiry.title}</title>
      <meta name="description" content={headData.inquiry.description}/>
    </Helmet>
      <div className="contact">
        <header className="header">
          <Header />
        </header>
        <nav className="breadcrumbs breadcrumbs-top">
            <ol>
                <NavLink activeClassName="active" to="/">
                    ホーム
                </NavLink>
                &nbsp;＞ &nbsp;
                <NavLink activeClassName="active" to="/inquiry">
                    お問い合わせ
                </NavLink>
            </ol>
        </nav>
        <section className="contact-wrapper">
          <article>
            <h1 className="contact-title">お問い合わせ</h1>
            <h2>利用規約の確認、楽曲の使用報告などお気軽にお問い合わせください。</h2>
            <div  className="contact-form">
              <form ref={form} onSubmit={sendEmail}>
                <div className="contact-item-title-container">
                  <div className="contact-item-title">
                    <p>ユーザ名</p>
                  </div>
                  <div className="contact-required">
                    <p>必須</p>
                  </div>
                </div>
                <div className="contact-item">
                  <input type="text" name="from_name" className="form-text" value={name} onChange={(event) => inputName(event.target.value)} required/>
                </div>
                <div className="contact-item-title-container">
                  <div className="contact-item-title">
                    <p>メールアドレス</p>
                  </div>
                  <div className="contact-required">
                    <p>必須</p>
                  </div>
                </div>
                <div className="contact-item">
                  <input type="email" name="from_email" className="form-text" value={email} onChange={(event) => inputEmail(event.target.value)} required/>
                </div>
                <div className="contact-item-title-container">
                  <div className="contact-item-title">
                    <p>お問い合わせ内容</p>
                  </div>
                  <div className="contact-required">
                    <p>必須</p>
                  </div>
                </div>
                <div className="contact-item">
                <textarea name="message" className="form-textarea" value={inquiryContents} onChange={(event) => inputinquiryContents(event.target.value)} required></textarea>
                </div>
                <input className="contact-submit" type="submit" value="送信" />
                <div className={isSuccess ? "contact-success is-success" : "contact-success"}>
                  <p>
                    送信に成功しました！
                  </p>
                </div>
                <div className="contact-text">
                  <p>Twitter 
                  <a href="https://twitter.com/moerugomimoeru" target="_blank"> (@moerugomimoeru)</a>
                    のDMでもお問い合わせを受け付けております。
                  </p>
                  <p>
                    ※お問い合わせを頂いてから、5日以内に返信いたします。<br/>返信がない場合お手数をおかけしますが、moerugomimoeru@gmail.comまで直接お問い合わせ下さい。
                  </p>
                </div>
              </form>
            </div>
          </article>
        </section>
        <Footer />
      </div>
  </React.StrictMode>
);
};

export default Inquiry;
