import "./Footer.css";
import React from "react";

function Footer() {
  return (
    <React.StrictMode>
      <footer className="footer">
          <p>
          Copyright © 2023 moeru music. All Rights Reserved.
          </p>
      </footer>
    </React.StrictMode>

  );
}

export default Footer;
