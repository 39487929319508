import './App.css';
import AudioPlayer from "./player//AudioPlayer";
import PlayList from "./PlayList";
import Category from './Category';
import tracks from "./tracks/tracks";
import categories from "./tracks/categories";
import React, { useState, useEffect, useRef } from "react";
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import headData from "./head-data.json";

import Footer from "./Footer";

const Home = ({
  changeCategory,
  toggleDownloadClassName,
  clickDonwloadOutSide,
  categoryIndex,
  tracksItems,
  changeTrack,
  trackIndex,
  isPlaying,
  changeToggleDownload,
  title,
  artistIndex,
  toPrevTrack,
  toNextTrack,
  setIsPlaying,
  trackProgress,
  volumeProgress,
  duration,
  onScrubEnd,
  onScrub,
  onScrubVolume,
  trackStyling,
  trackStylingVolume,
  startCategoryIndex,
  location,
  chengeSort,
  selectSortIndex
}) => {
  
  useEffect(() => {
    changeCategory(startCategoryIndex)
  }, []);


  return (
    <React.StrictMode>
    <Helmet>
      <title>{headData.home2.title}</title>
      <meta name="description" content={headData.home2.description}/>
    </Helmet>

      <div className="Home">
        <div className="wrapper">
          <article className="article">
            <div className="article-wrapper">
              <aside className="aside">
                <Category
                  changeCategory={changeCategory}
                  categories={categories}
                  categoryIndex={categoryIndex}
                />
              </aside>

              <main>
                <div className="main-container">
                  <section className="category-summary">
                    <h1>
                      フリーBGM：{categories[categoryIndex+1].title}
                    </h1>
                    <p>
                    運営者が制作したBGMを無料配布中です。<br/>
                    動画・配信など様々な用途に無料（商用利用可能）でお使いいただけます。
                    </p>
                    {
                      /*
                      <div className="precautions">
                      <p>
                        ※ 楽曲をご利用する際は、<Link to="/terms">利用規約</Link>をご確認ください。
                      </p>
                    </div>
                       */
                    }

                  </section>
                    
                  <PlayList
                    changeTrack={changeTrack}
                    tracks={tracksItems}
                    trackIndex={trackIndex}
                    isPlaying={isPlaying}
                    changeToggleDownload={changeToggleDownload}
                    changeCategory={changeCategory}
                    categories={categories}
                    categoryIndex={categoryIndex}
                    location={location}
                    chengeSort={chengeSort}
                    selectSortIndex={selectSortIndex}
                  />

                </div>
              </main>
            </div>
          </article>
        </div>
        <div className='home-footer'>
          
          <Footer />  
        </div>
      </div> 
      
    </React.StrictMode>
  );
}

export default Home;
