import art from './assets/logo512.png'
import adminicon from './assets/adminicon512.png'
import './About.css';
import './Artist';
import React, { useState, useEffect, useRef } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { ReactComponent as Arrow } from "./assets/arrow.svg"
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Link } from 'react-router-dom';
import { ReactComponent as Tiktok_icon} from './assets/tiktok_icon.svg'
import { ReactComponent as Facebook_icon} from './assets/facebook_icon.svg'
import { ReactComponent as Youtube_icon} from './assets/youtube_icon.svg'
import { ReactComponent as Twitter_icon} from './assets/twitter_icon.svg'
import { ReactComponent as Instagram_icon} from './assets/instagram_icon.svg'
import { ReactComponent as Web_icon} from './assets/web_icon.svg'
import { Helmet } from 'react-helmet';
import headData from "./head-data.json";
import history from "./history";

const About = () => {

  const historyLists = history.map((his, index) => {
    return (
      <React.StrictMode>
        <div className="history-contents" key={index}>
          <div className="history-dl">
            <div className="history-date">
              {his.date}
            </div>
            <div className="history-label">
              {his.label}
            </div>
          </div>
          <Link
            to={his.link}
            className="history-description">
            {his.description}
          </Link>
        </div>
      <div className="sub-title-line">
      </div>
    </React.StrictMode>

    );
})




  return (
    <React.StrictMode>
    <Helmet>
    <title>{headData.about.title}</title>
      <meta name="description" content={headData.about.description}/>
    </Helmet>
      <div className="About">
          <header className="header">
            <Header />
          </header>
          <section className="top-container">
            <article>
              <div className="top-title-container">
                <div className="top-title">
                  <h1>聴ける<br/>使える音楽を<br/>無料でダウンロード。</h1>
                </div>
              </div>
              <div className="learn-more">
                <AnchorLink href="#about-outer" offset="50">
                  Learn more
                  <Arrow />
                </AnchorLink>
              </div>
            </article>
          </section>
          <section className="outer" id="about-outer">
            <article>
                <div className="sub-title-container">
                  <h2 className="sub-title">
                    moeru music.とは？
                  </h2>
                  <div className="sub-title-line">
                  </div>  
                </div>
                <div className="contents-wrapper">
                  <div className="contents-container">
                    <div className="contents-text">
                      <h3 className="contents-main">
                      サイト運営者が制作した、商用利用可能なフリーBGMをダウンロードできるサイトです。
                      </h3>
                      <p className="contents-sub">
                      かわいい・おしゃれ（エモい）な雰囲気の楽曲を中心に掲載しています。Kawaii Future Bassや歌あり楽曲も掲載し始めました。
                      月一の更新を目安に随時掲載楽曲を増やしていく予定です。
                      </p>
                    </div>
                    <div className="contents-img">
                      <img src={art} alt="art" />
                    </div>
                  </div>
                  <div className="contents-btn">
                    <Link to="/">フリーBGM→</Link>
                  </div>
                </div>
                <div className="back-text">
                  <p>
                  ABOUT
                  </p>
                </div>
            </article>
          </section>
          <section className="outer">
            <article>
                <div className="sub-title-container">
                  <h2 className="sub-title">
                    特徴
                  </h2>
                  <div className="sub-title-line">
                  </div>
                </div>
                <div className="contents-wrapper">
                  <div className="feature-wrapper">
                    <div className="about-detail-box">
                      <div className="about-detail-title">
                        <h3>完全無料楽曲</h3>
                      </div>
                      <div className="about-detail-contents">
                        <p>当サイト内の楽曲は、全て無料でご利用いただけます。ご利用に際して、著作権・著作者の表記、使用報告は必須でありません。
                        <br/>（任意での表記、報告は大歓迎です！！）
                        </p>
                      </div>
                    </div>
                    <div className="about-detail-box">
                      <div className="about-detail-title">
                        <h3>商用利用可能</h3>
                      </div>
                      <div className="about-detail-contents">
                        <p>YouTubeなど収益が伴うサイトへの使用も可能です。利用規約の当該事項を除き、動画・ゲーム・イベントなど商用・非商用問わず、使用用途に制限はありません。</p>
                      </div>
                    </div>
                    {/*<div className="about-detail-box">
                      <div className="about-detail-title">
                        <h3>楽曲の掲載</h3>
                      </div>
                      <div className="about-detail-contents">
                        <p>世の作曲者様の知名度向上を兼ね、作曲者様が制作したフリー楽曲を掲載いたします。楽曲他、YouTubeやTwitterなどSNSリンクもご一緒に掲載いたします。<Link to="/artist">例</Link></p>
                      </div>
                    </div>*/}
                    <div className="about-detail-box">
                      <div className="about-detail-title">
                        <h3>楽曲コンセプト</h3>
                      </div>
                      <div className="about-detail-contents">
                        <p>かわいい・エモーショナルな雰囲気の楽曲を中心に掲載しています。BGMという用途だけでなく、お使いのプレイリストに入れてもらえるような、一つの曲として聴ける音楽を目指し制作を行っています。</p>
                      </div>
                    </div>
                  </div>
                  <div className="contents-btn">
                      <Link to="/terms">利用規約→</Link>
                  </div>
                </div>
                <div className="back-text">
                  <p>
                  FEATURE
                  </p>
                </div>
            </article>
          </section>
          <section className="outer">
            <article>
              <div className="sub-title-container">
                <h2 className="sub-title">
                  運営者情報
                </h2>
                <div className="sub-title-line">
                </div>
              </div>
              <div className="contents-wrapper">
                <div className="admin-top">
                  <div className="admin-img">
                    <img src={adminicon} alt="admin" />
                  </div>
                  <div className="admin-name">
                    <h3>もえるごみ</h3>
                    <a href="https://twitter.com/moerugomimoeru" target="_blank">(@moerugomimoeru)</a>
                  </div>
                  <div className="admin-sns">
                    <div className="artist-sns-container">
                      <div className="sns-box is-link">
                          <a href="https://twitter.com/moerugomimoeru" target="_blank">
                              <Twitter_icon />
                          </a>
                      </div>
                      <div className="sns-box is-link">
                          <a href="https://www.youtube.com/channel/UC3VHN3HnMH5bCKkAFoMMdjA" target="_blank">
                              <Youtube_icon />
                          </a>
                      </div>
                      <div className="sns-box is-link">
                          <a href="https://www.tiktok.com/@moerugomimoeru" target="_blank">
                              <Tiktok_icon />
                          </a>
                      </div>

                    </div>
                  </div>
                </div>
                <div className="admin-contents">
                  <p>

                  <br/>「もえるごみ」という名前の由来は、完全に音の響き。「もえる」というふわふわと可愛いイメージに対比して、「ごみ」という
                  濁った・汚いイメージの単語の組み合わさり方が気に入ったので付けました。
                  </p>
                </div>

              </div>
              <div className="back-text">
                <p>
                ADMIN
                </p>
              </div>
            </article>
          </section>

          <section className="outer">
            <article>
              <div className="sub-title-container">
                <h2 className="sub-title">
                  イラスト制作者様
                </h2>
                <div className="sub-title-line">
                </div>
              </div>
              <div className="contents-wrapper">
                
                <div className="contents-container illust-content">
                  <p>
                  動画でお借りしたイラストの制作者様です。
                  </p>
                  <ul>
                    <li>ノーコピーライトガール様
                      <ul>
                      <li>
                          Twitter：
                          <a href="https://twitter.com/nocopyrightgirl" target="_blank">
                            @nocopyrightgirl
                          </a>
                          </li>

                        <li>
                          ダウンロードサイト：
                          <a href="https://fromtheasia.com/illustration/nocopyrightgirl" target="_blank">
                          ＞
                          </a>
                        </li> 
                      </ul>


                    </li>
                    <li>丸結ぴか様
                      <ul>
                        <li>
                          Twitter：
                          <a href="https://twitter.com/chu0pika25" target="_blank">
                          @chu0pika25
                          </a>
                        </li>
                        <li>
                          Piapro ：
                          <a href="https://piapro.jp/pktsgreen333" target="_blank">
                          @pktsgreen333
                          </a>
                        </li>   
                      </ul>

                     
                    </li>
                    <li>はちろ様
                    <ul>
                        <li>
                          Twitter：
                          <a href="https://twitter.com/chiro1SK" target="_blank">
                          @chiro1SK
                          </a>
                        </li>
                        <li>
                          Piapro ：
                          <a href="https://piapro.jp/hachiro_860" target="_blank">
                          @hachiro_860
                          </a>
                        </li>   
                      </ul>
                    
                    </li>
                    <li>ちゃわんむし様
                    <ul>
                        <li>
                          Twitter：
                          <a href="https://twitter.com/chawanmushi_da" target="_blank">
                          @chawanmushi_da
                          </a>
                        </li>
                        <li>
                          Instagram ：
                          <a href="https://www.instagram.com/chawanmushi_dayon/" target="_blank">
                          @chawanmushi_dayon
                          </a>
                        </li>   
                      </ul>
                    
                    </li>
                  </ul>
                </div>
                  {/*<div className="contents-btn">
                    <Link to="/blog">ブログ→</Link>
                  </div>*/}
              </div>
              <div className="back-text">
                <p>
                ILLUST
                </p>
              </div>
            </article>
          </section>
          
          <section className="outer">
            <article>
              <div className="sub-title-container">
                <h2 className="sub-title">
                  新着情報・お知らせ
                </h2>
                <div className="sub-title-line">
                </div>
              </div>
              <div className="contents-wrapper">
                <div className="contents-container history-container">
                  {historyLists}
                </div>
              </div>
              <div className="back-text">
                <p>
                HISTORY
                </p>
              </div>
            </article>
          </section>

          <Footer/>
      </div>   
    </React.StrictMode>
  );
}

export default About;
