import './App.css';
import {   BrowserRouter,
  Route,
  Switch,
  NavLink,
  useParams,
} from 'react-router-dom';
import React, { useState, useEffect, useRef } from "react";
import About from "./About";
import Terms from './Terms';
import Inquiry from './Inquiry';
import Music from './Music';
import NotFound from './NotFound.jsx';
import ScrollToTop from './ScrollToTop';
import tracks from "./tracks/tracks";
import categories from "./tracks/categories";


function RouteDonwload() {
  const { id } = useParams();
  const track = tracks.find((track) => track.id === Number(id));
  if (track == undefined){
    return (
      <React.StrictMode>
        <NotFound />
      </React.StrictMode>
    );
  }else{
    return (
      <React.StrictMode>
        <Music />
      </React.StrictMode>
    );
  }
}

function RouteCategory() {
  const { id } = useParams();
  const category = categories.find((category) => category.categoryIndex === Number(id));
  if (category == undefined || Number(id) == -1){
    return (
      <React.StrictMode>
        <NotFound />
      </React.StrictMode>
    );
  }else{
    return (
      <React.StrictMode>
        <Music />
      </React.StrictMode>
    );
  }

}

function App() {

  return (
    <div className="App">
      {/* 
      react-router-dom 新バージョン
      <Router>
          <Routes>
            <Route path="/" element={<Music />}/>
            <Route path="/about" element={<About />}/>
            <Route path="/terms" element={<Terms />}/>
            <Route path="/inquiry" element={<Inquiry />}/>
            <Route path="/artist" element={<Music />}/>
            <Route path="/blog" element={<Blog />}/>
            <Route path="*" element={<NotFound />}/>
          </Routes>
      </Router>

      */}
      <BrowserRouter>
        <ScrollToTop>
          <Switch>
            <Route exact path="/">
              <Music />
            </Route>
            <Route path="/about">
              <About />
            </Route>
            <Route path="/terms" >
              <Terms />
            </Route>
            <Route path="/inquiry" >
              <Inquiry />
            </Route>
            {/*<Route path="/artist" >
              <Music />
            </Route>
            <Route exact path="/blog" >
              <BlogPostsList />
            </Route>}
            <Route path="/blog/:id">
              <RouteBlog />
            </Route>*/}
            <Route path="/m/:id">
              <RouteDonwload />
            </Route>
            <Route path="/c/:id">
              <RouteCategory />
            </Route>
            <Route>
              <NotFound />
            </Route>
          </Switch>
                    
        </ScrollToTop>
      </BrowserRouter>
      
    </div>
  );
}

export default App;
