import React from "react";
import "./Download.css";
import artists from "./tracks/artists";
import { Link } from 'react-router-dom';


const Download = ({
toggleDownloadClassName,
clickDonwloadOutSide,
trackDownloadIndex,
cntUp,
tracksItems
}) => {
    
    
    return (
        <div className={"download-wrapper " + toggleDownloadClassName} onClick={(e) => {clickDonwloadOutSide(e)}}>
            <div className="download-container">
            <div className="download-precautions precautions">
                <p>
                ※ 楽曲をご利用する際は、<Link to="/terms">利用規約</Link>をご確認ください。
                </p>
            </div>
            <div className="download-title">
                <p>
                    <Link to={"/m/" + (tracksItems[trackDownloadIndex].id)}>{tracksItems[trackDownloadIndex].title}/{artists[tracksItems[trackDownloadIndex].artistIndex].artistName}</Link>
                </p>
            </div>
            <div className="download-area">
                <div className="download-text">
                <p>
                ファイル形式を選んでください。
                </p>

                </div>
                <div className="download-btn-container">
                <a href={"https://drive.google.com/uc?export=download&id=" + tracksItems[trackDownloadIndex].downloadLinkMp3} 
                onClick={() => cntUp(tracksItems[trackDownloadIndex].id)}>
                    MP3
                </a>
                <a href={"https://drive.google.com/uc?export=download&id=" + tracksItems[trackDownloadIndex].downloadLinkWav} 
                onClick={() => cntUp(tracksItems[trackDownloadIndex].id)}>
                    WAV
                </a>
                </div>
            </div>
            <div className="contents-btn">
                <a href='/terms'>利用規約→
                </a>
            </div>
            </div>
        </div>
    );
};

export default Download;


