import React, { useState, useEffect, useRef } from "react";
import AudioControls from "./AudioControls";
import Backdrop from "./Backdrop";
import "./Player.css";
import { ReactComponent as Download } from "../assets/download.svg";
import { ReactComponent as Volume } from "../assets/volume.svg";
import { ReactComponent as Arrow } from "../assets/arrow.svg";
import {useWindowSize} from 'react-use';
import { Link } from 'react-router-dom';
import { ReactComponent as Youtube } from "../assets/youtube_icon.svg";
import artists from "../tracks/artists";

/*
 * Read the blog post here:
 * https://letsbuildui.dev/articles/building-an-audio-player-with-react-hooks
 */
const AudioPlayer = ({
  title,
  artistIndex,
  isPlaying,
  toPrevTrack,
  toNextTrack,
  setIsPlaying,
  trackProgress,
  volumeProgress,
  duration,
  onScrubEnd,
  onScrub,
  onScrubVolume,
  trackStyling,
  trackStylingVolume,
  trackIndex,
  changeToggleDownload,
  artwork,
  tracksItems

}) => {
  const [togglePlayerClassName, setTogglePlayerClassName] = useState("player-toggle-off");
  const [togglePlayerContainerClassName, setTogglePlayerContainerClassName] = useState("player-container-toggle-on");
  const [togglePlayerContainerClassNameSub, setTogglePlayerContainerClassNameSub] = useState("player-container-toggle-off");
  const [toggle, setToggle] = useState(false);
  
  const changeToggle = () => {
    if (toggle){
      setToggle(false);
      setTogglePlayerClassName("player-toggle-off");
      setTogglePlayerContainerClassName("player-container-toggle-on")
      setTogglePlayerContainerClassNameSub("player-container-toggle-off")
    }else{
      setToggle(true);
      setTogglePlayerClassName("player-toggle-on");
      setTogglePlayerContainerClassName("player-container-toggle-off")
      setTogglePlayerContainerClassNameSub("player-container-toggle-on")
    }
  };

  const { width, height } = useWindowSize();
  useEffect(() => {
    if((width > 768) && (toggle)){
      changeToggle();
    };


  }, [width, height]);

  return (
    <section className={togglePlayerClassName + " player"}>
      <div className={"player-container-top " + togglePlayerContainerClassName}>
        <div className="track-info-container">
          <div className="track-artwork">
            <img src={artwork} alt="artwork"/>
          </div>
          <div className="track-info">
          <h2 className="title"><Link to={"/m/" + (tracksItems[trackIndex].id)}>{title}</Link></h2>
          <h3 className="artist">{artists[artistIndex].artistName}</h3>  
          </div>
        </div>
        
        <div className="tarck-controls">
          <AudioControls
            isPlaying={isPlaying}
            onPrevClick={toPrevTrack}
            onNextClick={toNextTrack}
            onPlayPauseClick={setIsPlaying}
          />
          <input
            type="range"
            value={trackProgress}
            step="1"
            min="0"
            max={duration ? duration : `${duration}`}
            className="progress"
            onChange={(e) => onScrub(e.target.value)}
            onMouseUp={onScrubEnd}
            onKeyUp={onScrubEnd}
            style={{ background: trackStyling }}
          />  
        </div>
        <div className="track-other-container">
          <div className="track-volume">
            <Volume />
            <input
              type="range"
              value={volumeProgress}
              step="1"
              min="0"
              max="100"
              className="volume-progress"
              onChange={(e) => onScrubVolume(e.target.value)}
              style={{ background: trackStylingVolume }}
            />  
          </div>
          <div className={"track-youtube " + (tracksItems[trackIndex].youtubeId != "" ? "track-youtube-link": "")}>
            {tracksItems[trackIndex].youtubeId != ""
              ?
              <a href={"https://www.youtube.com/watch?v=" + tracksItems[trackIndex].youtubeId} target="_blank">
                  <Youtube />
              </a>
              :
              <Youtube />
              }
          </div>
          <div className="track-download">
            <button
              type="button"
              className="donwload"
              aria-label="Download"
              onClick={() => changeToggleDownload(trackIndex)}
              >
              <Download />
            </button>
          </div>


          <div className="track-arrow">
            <button onClick={changeToggle}>
              <Arrow />
            </button>
          </div>
        </div>
      </div>

      <div className={"player-container-sub " + togglePlayerContainerClassNameSub}>
        <div className="track-info-wrapper">
          <div className="track-info-container">
            <div className="track-artwork">
            <img src={artwork} alt="artwork"/>
            </div>
            <div className="track-info">
              <h2 className="title"><Link to={"/m/" + (tracksItems[trackIndex].id)}>{title}</Link></h2>
              <h3 className="artist">{artists[artistIndex].artistName}</h3>  
            </div>
          </div>

          <div className="track-other-container">
            <div className="track-volume">
              <Volume />
              <input
                type="range"
                value={volumeProgress}
                step="1"
                min="0"
                max="100"
                className="volume-progress"
                onChange={(e) => onScrubVolume(e.target.value)}
                style={{ background: trackStylingVolume }}
              />  
            </div>
            

            <div className={"track-youtube " + (tracksItems[trackIndex].youtubeId != "" ? "track-youtube-link": "")}>
            {tracksItems[trackIndex].youtubeId != ""
              ?
              <a href={"https://www.youtube.com/watch?v=" + tracksItems[trackIndex].youtubeId} target="_blank">
                  <Youtube />
              </a>
              :
              <Youtube />
              }
            </div>
            <div className="track-download">
              <button
                type="button"
                className="donwload"
                aria-label="Download"
                onClick={() => changeToggleDownload(trackIndex)}
                >
              <Download />
              </button>
            </div>

            <div className="track-arrow arrow-toggle-on">
              <button onClick={changeToggle}>
                <Arrow />
              </button>
            </div>
          </div>
        </div>

        <div className="tarck-controls">
          <AudioControls
            isPlaying={isPlaying}
            onPrevClick={toPrevTrack}
            onNextClick={toNextTrack}
            onPlayPauseClick={setIsPlaying}
          />
          <input
            type="range"
            value={trackProgress}
            step="1"
            min="0"
            max={duration ? duration : `${duration}`}
            className="progress"
            onChange={(e) => onScrub(e.target.value)}
            onMouseUp={onScrubEnd}
            onKeyUp={onScrubEnd}
            style={{ background: trackStyling }}
          />  
        </div>
      </div>
    </section>
  );
};

export default AudioPlayer;
