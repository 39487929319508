import './Terms.css';
import React, { useState, useEffect, useRef } from "react";
import Header from "./Header";
import { Link } from 'react-router-dom';
import Footer from "./Footer";
import { Helmet } from 'react-helmet';
import headData from "./head-data.json";

import { NavLink } from 'react-router-dom';

const Terms = () => {
  return (
    <React.StrictMode>
      <Helmet>
      <title>{headData.terms.title}</title>
      <meta name="description" content={headData.terms.description}/>
      </Helmet>
      <div className="Terms">
          <header className="header">
            <Header />
          </header>
          <section className="terms-container">
            <article>
              <div className="top-title terms-top-title">
                  <h1>利用規約</h1>
              </div>
            </article>
          </section>
          <nav className="breadcrumbs breadcrumbs-top">
            <ol>
                <NavLink activeClassName="active" to="/">
                    ホーム
                </NavLink>
                &nbsp;＞ &nbsp;
                <NavLink activeClassName="active" to="/terms">
                    利用規約
                </NavLink>
            </ol>
          </nav>
          <section className="terms-outer outer">
            <article>
              <div className="sub-title-container">
                  <h2 className="terms-sub-title sub-title-font">
                      当サイトのご利用について
                  </h2>
                  <div className="sub-title-line terms-sub-title-line">
                  </div>
              </div>
              <div className="contents-wrapper">
                  <ul>
                      <li>
                      <p>当サイト内の楽曲（以下、楽曲）は、利用規約の範囲内で商用（歌あり楽曲を除く）・非商用問わず、様々な用途に無料でお使いいただけます。</p>
                      </li>
                      <li>
                      <p>楽曲をご利用する場合は、必ず利用規約をご確認ください。</p>
                      </li>
                      <li>
                      <p>楽曲をダウンロードした時点で、利用規約に同意したものとみなします。</p>
                      </li>
                      <li>
                      <p>規約は予告なく変更する場合があります。</p>
                      </li>
                      <li>
                      <p>ご利用に際して不明な点はお気軽にお問い合わせください！</p>
                      </li>
                  </ul>
                  <div className="contents-btn">
                    <Link to="/inquiry">お問い合わせ</Link>
                  </div>
              </div>
            </article>
          </section>
          <section className="terms-outer outer">
            <article>
              <div className="sub-title-container">
                  <h2 className="terms-sub-title sub-title-font">
                      楽曲使用のご利用規約
                  </h2>
                  <div className="sub-title-line terms-sub-title-line">
                  </div>
              </div>
              <div className="contents-wrapper">
                  <ul>
                      <li>
                      <p>ご利用に際して、著作権・著作者の表記、使用報告は必須でありません。
                      <br/>（任意での表記や報告は大歓迎です。）</p>
                      </li>
                      <li>
                      <p>楽曲のみを販売・再配布・無断転載・複製する事はできません。</p>
                      </li>
                      <li>
                      <p>楽曲を使用した動画をYouTubeへアップロードする際に、使用楽曲をYouTube上のContent IDに登録しないでください。</p>
                      </li>
                      <li>
                      <p>楽曲はすべて無料でお使いいただけますが、著作権は放棄しておらず、著作権は楽曲制作者に帰属します。作曲者を偽る行為や著作権管理団体への登録は禁止します。</p>
                      </li>
                      <li>
                      <p>楽曲が主体となるコンテンツへの使用は禁止します。<br/>
                         - 禁止している使用方法<br/>
                         　・BGM○○分耐久動画<br/>
                         　・フリーBGMまとめ動画 等<br/>
                         - 問題の無い使用方法<br/>
                         　・雑談配信、作業配信<br/>
                         　・楽曲のMVの作成<br/>
                         　・歌ってみた動画 等<br/>
                       </p>
                      </li>
                  </ul>
              </div>
            </article>
          </section>
          <section className="terms-outer outer">
            <article>
              <div className="sub-title-container">
                  <h2 className="terms-sub-title sub-title-font">
                  楽曲の加工について
                  </h2>
                  <div className="sub-title-line terms-sub-title-line">
                  </div>
              </div>
              <div className="contents-wrapper">
                  <ul>
                      <li>
                      <p>楽曲のテンポやキー、音量の変更、ボーカルや音の追加、その他アレンジについては問題ありません。楽曲のアレンジ後も著作権は楽曲制作者に帰属しますので、利用規約の範囲内でご利用ください。</p>
                      </li>
                      <li>
                      <p>楽曲（BGM）へボーカル等を追加してアレンジした楽曲を配信する際などは事前に確認を取った上で楽曲制作者（もえるごみ）を明記してください。</p>
                      </li>
                  </ul>
              </div>
            </article>
          </section>
          <section className="terms-outer outer">
            <article>
              <div className="sub-title-container">
                  <h2 className="terms-sub-title sub-title-font">
                  歌あり楽曲のご利用について
                  </h2>
                  <div className="sub-title-line terms-sub-title-line">
                  </div>
              </div>
              <div className="contents-wrapper">
                  <ul>
                      <li>
                      <p>CeVIO AI 可不を使用している楽曲（offVocalの楽曲は除く）については、個人利用又は非商用でご利用ください。なお、動画投稿や配信での使用は、商用利用には該当しません。<br/>また、以下のCeVIO AI 音楽的同位体 利用規約をご確認したうえでご利用いただきますようお願いします。<br/>https://musical-isotope.kamitsubaki.jp/terms/<br/>なお現在、歌ありの楽曲については公開していません。</p>
                      </li>
                  </ul>
              </div>
            </article>
          </section>
          <section className="outer">
            <article>
              <div className="sub-title-container">
                  <h2 className="terms-sub-title sub-title-font">
                  免責事項
                  </h2>
                  <div className="sub-title-line terms-sub-title-line">
                  </div>
              </div>
              <div className="contents-wrapper">
                  <ul>
                      <li>
                      <p>楽曲の利用に際して生じた、いかなる損害損失については当サイトは一切責任を負いません。</p>
                      </li>
                  </ul>
              </div>
            </article>
          </section>
          <Footer />
      </div>
    </React.StrictMode>
  );
}

export default Terms;
