import './App.css';
import tracks from "./tracks/tracks";
import selectSort from "./tracks/selectSort";
import Header from "./Header";
import Home from "./Home";
import React, { useState, useEffect, useRef } from "react";
import {
  useParams,
  useHistory,
  useLocation,
} from 'react-router-dom';
import TrackSolo from './TrackSolo';
import AudioPlayer from "./player/AudioPlayer";
import Download from "./Download";

import { sendGtagEvent } from './gtag.js';
import axios from 'axios';

const Music = () => {
// State
const [trackIndex, setTrackIndex] = useState(0);
const [trackProgress, setTrackProgress] = useState(0);
const [isPlaying, setIsPlaying] = useState(false);
const [volumeProgress, setVolumeProgress] = useState(50);
const [categoryIndex, setCategoryIndex] = useState(-1);

const [tracksItems, setTracksItems] = useState(tracks);
const [selectSortIndex, setSelectSortIndex] = useState(0);

// Destructure for conciseness
const { title, artistIndex, audioSrc, categoryIndexTrack, date, length, downloadLinkMp3, downloadLinkWav, youtubeId, dlcnt, artwork} = tracksItems[trackIndex];
let audioTrackIndex = 0;
let trackLength = 8;
let beforeCategory = -1;
const [trackDownloadIndex, setTracDownloadkIndex] = useState(0);
const [toggleDownloadClassName, setToggleDownloadClassName] = useState("download-toggle-off");
const [toggleDownload, setToggleDownload] = useState(false);
const [isChangeCategory, setIsChangeCategory] = useState(false);

const changeToggleDownload = (index) => {
  if (index != -1){
    setTracDownloadkIndex(index);
    console.log(index)
  }
  if (toggleDownload){
    setToggleDownload(false);
    setToggleDownloadClassName("download-toggle-off");
  }else{
    setToggleDownload(true);
    setToggleDownloadClassName("download-toggle-on");
  }
};

//　ダウンロード要素クリック
const clickDonwloadOutSide = (e) => {
  if (e.target === e.currentTarget) {
    changeToggleDownload(-1);
  }
}
// Refs
const audioRef = useRef(new Audio(tracks[0].audioSrc));
const intervalRef = useRef();
const isReady = useRef(false);

// Destructure for conciseness
const { duration } = audioRef.current;

const { durationVolume } = 9.5;

const currentPercentage = duration
  ? `${(trackProgress / duration) * 100}%`
  : "0%";
const trackStyling = `
  -webkit-gradient(linear, 0% 0%, 100% 0%, color-stop(${currentPercentage}, #BCBCBC), color-stop(${currentPercentage}, #4D4D4D))
`;

const currentPercentageVolume = volumeProgress / 100
const trackStylingVolume = `
  -webkit-gradient(linear, 0% 0%, 100% 0%, color-stop(${currentPercentageVolume}, #BCBCBC), color-stop(${currentPercentageVolume}, #4D4D4D))
`;

const params = useParams(); 
const location = useLocation().pathname

const startTimer = () => {
  // Clear any timers already running
  clearInterval(intervalRef.current);
  
  intervalRef.current = setInterval(() => {
    if (audioRef.current.ended) {
      toNextTrack();
    } else {
      setTrackProgress(audioRef.current.currentTime);
    }
  }, [1000]);
};

const onScrub = (value) => {
  // Clear any timers already running
  clearInterval(intervalRef.current);
  audioRef.current.currentTime = value;
  setTrackProgress(audioRef.current.currentTime);
};

const onScrubEnd = () => {
  startTimer();
  return
};
const playStop = () => {
  if (!isPlaying) {
    setIsPlaying(true);
    audioRef.current.pause();
    
    if (isChangeCategory){
      audioRef.current = new Audio(tracksItems[audioTrackIndex].audioSrc);
      setIsChangeCategory(false);
    }

    audioRef.current.play();
    startTimer();
    audioRef.current.volume = volumeProgress / 100;
  } else {
    setIsPlaying(false);
    audioRef.current.pause();
  }
}

const playSound = () => {
  audioRef.current.pause();
  audioRef.current = new Audio(tracksItems[audioTrackIndex].audioSrc);
  setTrackProgress(audioRef.current.currentTime);
  audioRef.current.volume = volumeProgress / 100;
  
  audioRef.current.play();
  setIsPlaying(true);
  startTimer();
}

const toPrevTrack = () => {
  if (trackIndex - 1 < 0) {
    setTrackIndex(tracksItems.length - 1);
    audioTrackIndex =tracksItems.length - 1;
  } else {
    setTrackIndex(trackIndex - 1);
    audioTrackIndex = trackIndex-1;
  }
  playSound();
};

const toNextTrack = () => {
  if (trackIndex < tracksItems.length - 1) {
    setTrackIndex(trackIndex + 1);
    audioTrackIndex = trackIndex+1;
  } else {
    setTrackIndex(0);
    audioTrackIndex = 0;
  }
  playSound();
};

const changeTrack = (nexTrackIndex) => {
  audioTrackIndex = nexTrackIndex;


  //再生中＆同じ
  if (isPlaying && nexTrackIndex==trackIndex) {
    playStop();
  //再生中＆違う
  }else if(isPlaying){
    setTrackIndex(nexTrackIndex);
    playSound();
  //停止中＆同じ
  }else if(nexTrackIndex==trackIndex){
    playStop();
  //停止中＆違う
  }else{
    setTrackIndex(nexTrackIndex);
    playSound();
  }
}

const changeInitTrack = (nexTrackIndex) =>{
  audioTrackIndex = nexTrackIndex;

  setTrackIndex(nexTrackIndex);
  audioRef.current.pause();
  audioRef.current = new Audio(tracksItems[audioTrackIndex].audioSrc);
  setTrackProgress(audioRef.current.currentTime);
  audioRef.current.volume = volumeProgress / 100;
}

const onScrubVolume = (value) => {
  audioRef.current.volume = value / 100;
  setVolumeProgress(value);
};

const changeCategory = (newCategoryIndex) => {
  let newTracks = []
  tracks.forEach(track => {
    if (newCategoryIndex == -1){
      newTracks.push(track);
    }else{
      if (track.categoryIndex.includes(newCategoryIndex)){
        newTracks.push(track);
      }
    }
  })
  setCategoryIndex(newCategoryIndex);
  setTracksItems(newTracks);
  setIsPlaying(false);
  setTrackIndex(0);
  audioRef.current.pause();
  setIsChangeCategory(true);
  setSelectSortIndex(0)

}
const chengeSort = (value) => {
  let newTracks = []
  if (value === selectSort[0].value){
    setSelectSortIndex(0)
    newTracks = tracksItems.sort(compareDate)
  }else if (value === selectSort[1].value){
    setSelectSortIndex(1)
    newTracks = tracksItems.sort(compareDlCnt)
  }else if (value === selectSort[2].value){
    setSelectSortIndex(2)
    newTracks = tracksItems.sort(compareLength)
  }else if (value === selectSort[3].value){
    setSelectSortIndex(3)
    newTracks = tracksItems.sort(compareBpm)
  } else {
    setSelectSortIndex(4)
    newTracks = tracksItems.sort(compareDate)
  }
  setTracksItems(newTracks);
  setIsPlaying(false);
  setTrackIndex(0);
  audioRef.current.pause();
  setIsChangeCategory(true);
}

function compareDate( a, b ){
  var r = 0;
  if( a.date < b.date ){ r = 1; }
  else if( a.date > b.date ){ r = -1; }

  return r;
}

function compareBpm( a, b ){
  var r = 0;
  if( a.bpm < b.bpm ){ r = 1; }
  else if( a.bpm > b.bpm ){ r = -1; }

  return r;
}

function compareLength( a, b ){
  var r = 0;
  if( a.length < b.length ){ r = 1; }
  else if( a.length > b.length ){ r = -1; }

  return r;
}

function compareDlCnt( a, b ){
  var r = 0;
  if( a.dlcnt < b.dlcnt ){ r = 1; }
  else if( a.dlcnt > b.dlcnt ){ r = -1; }

  return r;
}

useEffect(() => {

}, [isPlaying]);

// Handles cleanup and setup when changing tracks
useEffect(() => {

}, [trackIndex]);

const cntUp = (index) =>{
  sendGtagEvent("download", "", "")
  const url = 'https://moerumusic.com/flask/cntup';

  posting(url, index);
}

const posting = (url, jsonData) => {
  const data = {
      jsonData
  };

  requestPost(url, data)
  .then(response => {
      if (response.data.status === 0){
      }else{

      }
  }).catch(error => {
      
  });
};

const requestPost = (url, data) => {
  return axios.post(url, data);
};


const getCnt = () =>{
  const url = 'https://moerumusic.com/flask/getCnt';
  getting(url);
}

const getting = (url) => {
  requestGet(url)
  .then(response => {
      if (response.data.status === 0){
        let newTracks = []
        const res = response.data.dl;
        for (let i=0 ; i < tracksItems.length; i++){
          newTracks.push(tracksItems[i])
          for (let j=0 ; j < res.length; j++){
            if(newTracks[i].id === res[j].id){
              newTracks[i].dlcnt = res[j].cnt
              break;
            }
          }
        } 
      setTracksItems(newTracks)
    }
  }).catch(error => {
      
  });
};

const requestGet = (url) => {
  return axios.get(url);
};

useEffect(() => {
  getCnt()
  
  return () => {
    audioRef.current.pause();
    clearInterval(intervalRef.current);
  };
}, []);

  const startCategoryIndex = (location.includes('/c/') && Number(params.id) != -1) ?  Number(params.id) : -1

  return (
    <div className="Music">
      <div className="wrapper">
        <header className="header">
          <Header />
        </header>
        <Download
            toggleDownloadClassName={toggleDownloadClassName}
            clickDonwloadOutSide={clickDonwloadOutSide}
            trackDownloadIndex={trackDownloadIndex}
            cntUp={cntUp}
            tracksItems={tracksItems}
        />
        {(location == "/") || (location.includes('/c/'))
        ?   <Home 
                changeCategory={changeCategory}
                toggleDownloadClassName={toggleDownloadClassName}
                clickDonwloadOutSide={clickDonwloadOutSide}
                categoryIndex={categoryIndex}
                tracksItems={tracksItems}
                changeTrack={changeTrack}
                trackIndex={trackIndex}
                isPlaying={isPlaying}
                changeToggleDownload={changeToggleDownload}
                title={title}
                artistIndex={artistIndex}
                toPrevTrack={toPrevTrack}
                toNextTrack={toNextTrack}
                setIsPlaying={playStop}
                trackProgress={trackProgress}
                volumeProgress={volumeProgress}
                duration={duration}
                onScrubEnd={onScrubEnd}
                onScrub={onScrub}
                onScrubVolume={onScrubVolume}
                trackStyling={trackStyling}
                trackStylingVolume={trackStylingVolume}
                startCategoryIndex={startCategoryIndex}
                location={location}
                chengeSort={chengeSort}
                selectSortIndex={selectSortIndex}
            />
        :   
        <TrackSolo 
          tracksItems={tracksItems}
          changeTrack={changeTrack}
          trackIndex={trackIndex}
          isPlaying={isPlaying}
          changeToggleDownload={changeToggleDownload}
          params={params}
          changeCategory={changeCategory}
          categoryIndex={categoryIndex}
          cntUp={cntUp}
          location={location}
          changeInitTrack={changeInitTrack}
          chengeSort={chengeSort}
          selectSortIndex={selectSortIndex}
        />
        
        
        /*<Artist 
                changeCategory={changeCategory}
                toggleDownloadClassName={toggleDownloadClassName}
                clickDonwloadOutSide={clickDonwloadOutSide}
                categoryIndex={categoryIndex}
                tracksItems={tracksItems}
                changeTrack={changeTrack}
                trackIndex={trackIndex}
                isPlaying={isPlaying}
                changeToggleDownload={changeToggleDownload}
                title={title}
                artistIndex={artistIndex}
                toPrevTrack={toPrevTrack}
                toNextTrack={toNextTrack}
                setIsPlaying={playStop}
                trackProgress={trackProgress}
                volumeProgress={volumeProgress}
                duration={duration}
                onScrubEnd={onScrubEnd}
                onScrub={onScrub}
                onScrubVolume={onScrubVolume}
                trackStyling={trackStyling}
                trackStylingVolume={trackStylingVolume}
            />*/     
        }
        
        <AudioPlayer 
          title={title}
          artistIndex={artistIndex}
          isPlaying={isPlaying}
          toPrevTrack={toPrevTrack}
          toNextTrack={toNextTrack}
          setIsPlaying={playStop}
          trackProgress={trackProgress}
          volumeProgress={volumeProgress}
          duration={duration}
          onScrubEnd={onScrubEnd}
          onScrub={onScrub}
          onScrubVolume={onScrubVolume}
          trackStyling={trackStyling}
          trackStylingVolume={trackStylingVolume}
          trackIndex={trackIndex}
          changeToggleDownload={changeToggleDownload}
          artwork={artwork}
          tracksItems={tracksItems}
        />
      </div>
     
    </div>
  );
}

export default Music;
