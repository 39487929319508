import './TrackSolo.css';
import style from './Youtube.module.css';
import React, { useState, useEffect, useRef } from "react";
import { Helmet } from 'react-helmet';
import headData from "./head-data.json";
import { useParams } from "react-router-dom";

import Header from "./Header";
import Footer from "./Footer";

import tracks from "./tracks/tracks";
import artists from "./tracks/artists";
import categories from "./tracks/categories";
import { NavLink } from 'react-router-dom';
import YouTube from 'react-youtube';
import { Link } from 'react-router-dom';
import PlayList from "./PlayList";

const TrackSolo = ({
  tracksItems,
  changeTrack,
  trackIndex,
  isPlaying,
  changeToggleDownload,
  params,
  changeCategory,
  categoryIndex,
  cntUp,
  location,
  changeInitTrack,
  chengeSort,
  selectSortIndex
}) => {

  const track = tracks.find((track) => track.id === Number(params.id));

  const categoryLists = () =>{
    var category_list = [];
    for(let category_index = 0; category_index < categories.length; category_index++){
        if(track.categoryIndex.includes(category_index-1)){
            category_list.push(
                <div className="item-category-title" key={category_index}
                onClick={() => changeCategory(category_index-1)}>
                    {categories[category_index].title}
                </div>
            )
        }

    }
    return <div className="item-category">{category_list}</div>
}

useEffect(() => {
  changeInitTrack(tracks.length - Number(params.id))
  return () => {

  };
}, []);

  return (
    <React.StrictMode>
        <Helmet>
        <title>{track.title+"|"+headData.download.title}</title>
        <meta name="description" content={"フリーBGM・無料音楽素材の「" + track.title + "」のダウンロードページです。かわいい・エモーショナルな楽曲を中心に、フリーBGM・音楽素材を無料で配布しております。" }/>
        </Helmet>
        <div className="TrackSolo">
          <div className="trackso-wrapper">

            <div className="top-title trackso-top-title">
                <h1>フリーBGM・音楽素材 ダウンロード</h1>
            </div>
            <section className='outer'>
                <nav className="breadcrumbs breadcrumbs-top">
                  <ol>
                    <NavLink activeClassName="active" to="/">
                        ホーム
                    </NavLink>
                    &nbsp;＞ &nbsp;
                    <NavLink activeClassName="active" to={"/m/" + (params.id)}>
                      {track.title}
                    </NavLink>
                  </ol>
                </nav>
              <article>

              <div className="trackso-mdcontainer">
                <div className="trackso-youtube">
                  <div class="trackso-youtube-responsive">
                  <iframe width="100%" height="100%" src={"https://www.youtube.com/embed/" + (track.youtubeId)} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                  </div>
                
                <div className="tt">
                  <p className="date">{track.date}</p>
                  <p className="bpm">ループ:{track.loop ? "〇": "X"}</p>
                  <p className="bpm">BPM:{track.bpm}</p>
                  <p className="bpm">DL数:{track.dlcnt}</p>
                </div>
                
                {categoryLists()}
                <p className="title">
                {track.title} / もえるごみ</p>
                <div className="trackso-line"></div>

                <div className="trackso-youtube-contents">
                <p className="trackso-description">
                  
                {track.description}
                </p>
                
                {
                  track.updateDate !== "" ? 
                  <p className="trackso-description under-line">
                  最終更新 {track.updateDate} {track.updateDescription}<br/>
                  </p>
                  :
                  <></>
                }

                </div>
                </div>
                <div className="trackso-download">
                  <p className="trackso-dl-title">
                    Free Download
                  </p>


                  <div className="trackso-download-container2">

                    <div className="trackso-download-container">
                      <p className="trackso-dl-sub-title">
                        オリジナルVer. _ {track.length}
                      </p>

                      <div className="download-btn-container">
                      <a href={"https://drive.google.com/uc?export=download&id=" + track.downloadLinkMp3}
                      onClick={() => cntUp(track.id)}>
                        MP3
                      </a>
                      <a href={"https://drive.google.com/uc?export=download&id=" + track.downloadLinkWav}
                      onClick={() => cntUp(track.id)}>
                        WAV
                      </a>
                      </div>
                    </div>

                    <div className={"trackso-download-container" + (track.loop === false ? "-toggle": "")}>
                      <p className="trackso-dl-sub-title">
                        ループVer. _ {track.lengthLoop}
                      </p>

                      <div className="download-btn-container">
                      <a href={"https://drive.google.com/uc?export=download&id="  + track.downloadLinkMp3Loop}
                      onClick={() => cntUp(track.id)}>
                        MP3
                      </a>
                      <a href={"https://drive.google.com/uc?export=download&id="  + track.downloadLinkWavLoop}
                      onClick={() => cntUp(track.id)}>
                        WAV
                      </a>
                      </div>
                    </div>
                    
                    <div className="contents-btn">
                      <a href='/terms'>利用規約→
                      </a>
                    </div>
                    <p className='download-precautions precautions'>
                    ※ 楽曲をご利用する際は、利用規約をご確認ください。
                    </p>

        
                  </div>
              
                </div>
              </div>
              <p className="trackso-dl-title" id="trackso-other-track">
                その他の楽曲
              </p>
              <PlayList
                  changeTrack={changeTrack}
                  tracks={tracksItems}
                  trackIndex={trackIndex}
                  isPlaying={isPlaying}
                  changeToggleDownload={changeToggleDownload}
                  changeCategory={changeCategory}
                  categories={categories}
                  categoryIndex={categoryIndex}
                  location={location}
                  chengeSort={chengeSort}
                  selectSortIndex={selectSortIndex}
              />

              </article>

              
            

            </section>
            
          
          
          </div>
                      
        </div>
        <Footer/>
    </React.StrictMode>
  );
}

export default TrackSolo;
