import React from "react";
import "./Category.css";
import { Link } from 'react-router-dom';

const Category = ({
  changeCategory,
  categories,
  categoryIndex
}) => {
    const categoryLists = categories.map((value, index) => {
        return (
            <li className={index===categoryIndex+1 ? "select"  : "" } key={index}>
                <Link to={value.categoryIndex == -1 ? "/" : "/c/" + (value.categoryIndex)}
                    className="categoryBtn"
                    onClick={() => changeCategory(value.categoryIndex)}
                    >
                    {value.title}
                </Link>

            </li>
        );
    });

    return (
        <div className="aside-sticky">
            <div className="aside-container">
                <div className="aside-title">
                  <h2>
                  カテゴリー
                  </h2>
                </div>
                <hr className="aside-line"></hr>
            </div>
            <div className="category-container">
              <ul className="category-list">
                  {categoryLists}
              </ul>
            </div>
        </div>
    );
};

export default Category;
