import React, { useState, useEffect, useRef } from "react";
import { ReactComponent as Download } from "./assets/download.svg";
import { ReactComponent as Play } from "./assets/play.svg";
import { ReactComponent as Pause } from "./assets/pause.svg";
import { ReactComponent as Youtube } from "./assets/youtube_icon.svg";
import selectSort from "./tracks/selectSort";

import "./PlayList.css"
import { Link,
    useLocation} from 'react-router-dom';
import artists from "./tracks/artists";
import AnchorLink from 'react-anchor-link-smooth-scroll';

const PlayList = ({
  changeTrack,
  tracks,
  trackIndex,
  isPlaying,
  changeToggleDownload,
  changeCategory,
  categories,
  categoryIndex,
  location,
  chengeSort,
  selectSortIndex
}) => {
    const selectCategoryLists = () =>{
        var category_list = [];
        for(let category_index = 0; category_index < categories.length; category_index++){
            var link = category_index == 0 ? "/" : "/c/" + (category_index-1);
                if (location.includes("/m/")){
                    category_list.push(
                        <AnchorLink
                        href="#trackso-other-track"
                        offset="50"
                        className={"item-category-title " + (category_index===categoryIndex+1 ? "item-category-select"  : "" )} key={category_index}
                            onClick={() => changeCategory(category_index-1)}
                            >
                            {categories[category_index].title}
                        </AnchorLink>
                    )
                }else{
                    category_list.push(
                    <Link to={link}
                        className={"item-category-title " + (category_index===categoryIndex+1 ? "item-category-select"  : "" )} key={category_index}
                        onClick={() => changeCategory(category_index-1)}
                        >
                        {categories[category_index].title}
                    </Link>
                    )
                }
        }
        return <div>{category_list}</div>
      }

    const categoryLists = (track_index) =>{
        var category_list = [];
        for(let category_index = 0; category_index < categories.length; category_index++){
            if(tracks[track_index].categoryIndex.includes(category_index-1)){
                var link = category_index == 0 ? "/" : "/c/" + (category_index-1);
                if (location.includes("/m/")){
                    category_list.push(
                    <AnchorLink
                    href="#trackso-other-track"
                    offset="50"
                        className="item-category-title" key={category_index}
                        onClick={() => changeCategory(category_index-1)}
                            >
                            {categories[category_index].title}
                    </AnchorLink>
                    )
                }else{
                    category_list.push(
                        <Link to={link}
                        className="item-category-title" key={category_index}
                        onClick={() => changeCategory(category_index-1)}
                            >
                            {categories[category_index].title}
                        </Link>
                    )
                }
                category_list.push( 
                )
            }
        }
        return <div className="item-category">{category_list}</div>
    }

    const trackLists = tracks.map((track, index) => {
        return (
            <div className="mus-row" key={index}>
                <div className="mus-ih mus">
                    <div className="mus-item item-play">
                        {isPlaying && trackIndex==index ? (
                            <button
                            type="button"
                            className="btn-pause"
                            aria-label="Previous"
                            onClick={() => changeTrack(index)}
                            >
                                
                            <img className="artwork" src={track.artwork} alt="artwork"/>
                            <Pause />
                            
                            </button>
                        ) : (
                            <button
                            type="button"
                            className="btn-play"
                            aria-label="Previous"
                            onClick={() => changeTrack(index)}
                            >
                                
                            <img className="artwork" src={track.artwork} alt="artwork"/>
                            <Play />
                            
                            </button>
                        )}
                    </div>
                    <div className="mus-item item-detail">
                        <div className="mus-item item-top-title">
                            <div className="item-title">
                                <Link to={"/m/" + (track.id)}>
                                    {track.title}
                                </Link>
                            </div>
                            
                            <div className="item-artist">{artists[track.artistIndex].artistName}</div>
                        </div>
                    </div>
                    {/*<div className="mus-item item-date"><span>{track.date}</span></div>*/}
                    <div className="mus-item item-length"><span>{track.length}</span></div>
                    <div className="mus-item item-bpm"><span>　{track.bpm}</span></div>
                    <div className="mus-item item-dl"><span>　{track.dlcnt}</span></div>
                    <div className={"mus-item item-youtube" + (track.youtubeId != "" ? " item-youtube-link": "")}>
                        {track.youtubeId != ""
                        ?
                        <a href={"https://www.youtube.com/watch?v=" + track.youtubeId} target="_blank">
                            <Youtube />
                        </a>
                        :
                        <a><Youtube /></a>
                        }

                    </div>
                    <div className="mus-item item-download">
                        <button
                                type="button"
                                className="donwload"
                                aria-label="Download"
                                onClick={() => changeToggleDownload(index)}
                                >
                            <Download />
                        </button>
                    </div>
                </div>
                <div className="mus-item item-bottom">
                        {categoryLists(index)}

                </div>

            </div>
        );
    })
    
    return (
        <div className="playlist-outer">
                <div className="category-list-sub">
                    {selectCategoryLists()} 
                </div>
            <div className="category-description">
                <p>{categories[categoryIndex+1].description}</p>
            </div>
            <div className="total-sort">
                <div className="totall-result">
                    {tracks.length.toString()}曲
                </div>
                <div className="sort-select">
                    <div hidden>
                        {selectSort[selectSortIndex].title}
                    </div>
                    <select name="sort" onChange={(e) => chengeSort(e.target.value)} value={selectSort[selectSortIndex].value}>
                        <option value={selectSort[0].value} selected>{selectSort[0].title}</option>
                        <option value={selectSort[1].value}>{selectSort[1].title}</option>
                        <option value={selectSort[2].value}>{selectSort[2].title}</option>
                        <option value={selectSort[3].value} >{selectSort[3].title}</option>
                    </select>
                </div>
            </div>

            <div className="playlist-container">


                <div className="mus-row-head">
                <div className="mus-ih mus-head">
                    <div className="mus-head-item"><span></span></div>
                    <div className="mus-head-item"><span>タイトル</span></div>
                    {/* <div className="mus-head-item head-item-date"><span>追加日</span></div>*/ }
                    <div className="mus-head-item head-item-length"><span>時間</span></div>
                    <div className="mus-head-item head-item-bpm"><span>BPM</span></div>
                    <div className="mus-head-item head-item-dl"><span>DL数</span></div>
                </div>
                </div>
                {trackLists}
            </div>
        </div>

    );
};

export default PlayList;
