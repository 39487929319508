export default [
  {
    title: "全ての楽曲",
    categoryIndex: -1,
    text: "",
    label: "All",
    description: ""
  },
  {
    title: "可愛い/明るい",
    categoryIndex: 0,
    text: "",
    label: "kawaii",
    description: "VTuberさんなどの配信者様にぴったりの明るく可愛い、アップテンポなBGMです。"
  },
  {
    title: "おしゃれ/エモい",
    categoryIndex: 1,
    text: "",
    label: "emotional",
    description: "作業用・勉強用などにぴったりのエモい/おしゃれなBGMです。"
  },
  {
    title: "雨/自然",
    categoryIndex: 2,
    text: "",
    label: "rain",
    description: "雨や水中などの自然をイメージした心が落ち着くBGMです。"
  },
  {
    title: "朝/生活",
    categoryIndex: 3,
    text: "",
    label: "morning",
    description: "日常生活を感じさせるBGMです。特に朝の支度をイメージしています。"
  },
  {
    title: "Kawaii Future Bass",
    categoryIndex: 4,
    text: "",
    label: "Kawaii FutureBass",
    description: "Kawaii FutureBassを意識したアップテンポの可愛いBGMです。"
  },
  {
    title: "ゲーム/8bit",
    categoryIndex: 5,
    text: "",
    label: "game",
    description: "ゲーム音楽などに最適な8bit・Chiptune系のアップテンポなBGMです。"
  }
  /*
  {
    title: "暗め",
    categoryIndex: 1,
    text: "",
    label: "Dark"
  },
  {
    title: "うるさめ",
    categoryIndex: 2,
    text: "",
    label: "Wow"
  },
  {
    title: "その他",
    categoryIndex: 3,
    text: "",
    label: "Other"
  },*/
];
