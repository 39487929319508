import breakfast from "../music/breakfast.mp3";
import amagaeru from "../music/amagaeru.mp3";
import zihanki from "../music/zihanki.mp3";
import ikuseisou from "../music/ikuseisou.mp3";
import basutei from "../music/basutei.mp3";
import universe from "../music/universe.mp3";
import freefree from "../music/freefree.mp3";
import bye from "../music/bye-bye.mp3";
import ohayo from "../music/ohayo.mp3";
import ice from "../music/ice.mp3";
import airplane from "../music/airplane.mp3";
import bpm150 from "../music/bpm150.mp3";
import soranaka from "../music/soranaka.mp3";
import nemui from "../music/nemui.mp3";
import window from "../music/window.mp3";
import donut from "../music/donut.mp3";
import aquarium from "../music/aquarium.mp3";
import yellow from "../music/yellow.mp3";
import gameover from "../music/gameover.mp3";
import monoui_off from "../music/monoui_offvocal.mp3";
import meetup from "../music/meetup.mp3";
import rainy from "../music/rainy.mp3";
import reika from "../music/reika.mp3";
import onemin from "../music/1minute.mp3";
import yakan from "../music/夜間飛行.mp3";
import cell_kaf_off from "../music/cell offVocal.mp3";
import image from "../music/image.mp3";
import swim_off from "../music/swim offVocal.mp3";
import sept from "../music/sept.mp3";


import art_breakfast from "../assets/artwork/breakfast.jpg";
import art_amagaeru from "../assets/artwork/amagaeru.jpg";
import art_zihanki from "../assets/artwork/zihanki.jpg";
import art_ikuseisou from "../assets/artwork/ikuseisou.jpg";
import art_basutei from "../assets/artwork/basutei.jpg";
import art_ohayo from "../assets/artwork/ohayo.jpg";
import art_ice from "../assets/artwork/ice.jpg";
import art_airplane from "../assets/artwork/airplane.jpg";
import art_bpm150 from "../assets/artwork/bpm150.jpg";
import art_soranaka from "../assets/artwork/soranaka.jpg";
import art_nemui from "../assets/artwork/nemui.jpg";
import art_other from "../assets/artwork/other.jpg";
import art_window from "../assets/artwork/window.jpg";
import art_donut from "../assets/artwork/donut.jpg";
import art_aquarium from "../assets/artwork/aquarium.jpg";
import art_yellow from "../assets/artwork/yellow.jpg";
import art_gameover from "../assets/artwork/gameover.jpg";
import art_monoui from "../assets/artwork/monoui.jpg";
import art_meetup from "../assets/artwork/meetup.jpg";
import art_rainy from "../assets/artwork/rainy.jpg";
import art_reika from "../assets/artwork/reika.jpg";
import art_onemin from "../assets/artwork/onemin.jpg";
import art_yakan from "../assets/artwork/yakan.jpg";
import art_cell from "../assets/artwork/cell.jpg";
import art_image from "../assets/artwork/image.jpg";
import art_swim from "../assets/artwork/swim.jpg";
import art_sept from "../assets/artwork/sept.jpg";

export default [
  {
    id: 29,
    title: "Sept.",
    artistIndex: 0,
    audioSrc: sept,
    categoryIndex: [0, 1, 2],
    date: "24/09/02",
    length: "01:26",
    lengthLoop: "",
    downloadLinkMp3: "1X66eHwmB3oICaY_blAlglyR2nWFFuT8T",
    downloadLinkWav: "1DNGmDTw4mxZGr34r1ynOq0uZ89rkmLLS",
    downloadLinkMp3Loop: "",
    downloadLinkWavLoop: "",
    youtubeId: "dY6ASsT8Ef0",
    dlcnt: 0,
    artwork: art_sept,
    bpm: 85,
    loop: false,
    description: "9月をイメージした楽曲です。\n配信・作業用様々な用途にお使いください！\n\nIllust：咲里キリコ 様\n→ Twitter：https://x.com/KiricoSkst\n→ Piapro：https://piapro.jp/kirico_sk",
    updateDate: "",
    updateDescription: "",
  },
  {
    id: 28,
    title: "swim offVocal",
    artistIndex: 0,
    audioSrc: swim_off,
    categoryIndex: [1, 2],
    date: "24/04/21",
    length: "01:31",
    lengthLoop: "",
    downloadLinkMp3: "1YBnnBwbcEHVYmnNZnfOG9otFcArGuvne",
    downloadLinkWav: "1NqUdiPvYX5K1QsiprglLLdDEGpmakrsb",
    downloadLinkMp3Loop: "",
    downloadLinkWavLoop: "",
    youtubeId: "XSgAobPS5O4",
    dlcnt: 0,
    artwork: art_swim,
    bpm: 110,
    loop: false,
    description: "swim feat.可不のinst（offvocal）バージョンになります。ご自由にお使いください！",
    updateDate: "",
    updateDescription: "",
  },
  {
    id: 27,
    title: "image",
    artistIndex: 0,
    audioSrc: image,
    categoryIndex: [1, 2, 3],
    date: "24/04/14",
    length: "01:07",
    lengthLoop: "",
    downloadLinkMp3: "1jVKRWNRBfkwkdOugaZZeHLirFmDFn73p",
    downloadLinkWav: "1SUcXL7IGuo0FecpbBgt0NB9vuKGrMmln",
    downloadLinkMp3Loop: "",
    downloadLinkWavLoop: "",
    youtubeId: "R6uikGP1MIM",
    dlcnt: 0,
    artwork: art_image,
    bpm: 95,
    loop: false,
    description: "夕暮れ時をイメージした楽曲です。ご自由にお使いください！",
    updateDate: "",
    updateDescription: "",
  },
  {
    id: 26,
    title: "cell offVocal",
    artistIndex: 0,
    audioSrc: cell_kaf_off,
    categoryIndex: [0, 1],
    date: "23/12/09",
    length: "03:04",
    lengthLoop: "",
    downloadLinkMp3: "1BrsENS29bmqPz2I61HbbKIWxkLB_wdkI",
    downloadLinkWav: "18mOL546SeqVsdztmTPUJB12va4C3VbQK",
    downloadLinkMp3Loop: "",
    downloadLinkWavLoop: "",
    youtubeId: "XH1OGduwgD4",
    dlcnt: 0,
    artwork: art_cell,
    bpm: 105,
    loop: false,
    description: "cell feat. 可不のinst（offvocal）バージョンになります。ご自由にお使いください！\n\nIllust：ふわつくね 様\n→ Piapro：https://piapro.jp/fuwa29ne",
    updateDate: "",
    updateDescription: "",
  },
  {
    id: 25,
    title: "夜間飛行",
    artistIndex: 0,
    audioSrc: yakan,
    categoryIndex: [1, 2],
    date: "23/11/24",
    length: "01:31",
    lengthLoop: "",
    downloadLinkMp3: "1YfVA3WOnqel0ibIf6S7Ny3SftuOg9wW5",
    downloadLinkWav: "1R5earSwWQ7wZuVhluzXMv5ZZw7SWU-hI",
    downloadLinkMp3Loop: "",
    downloadLinkWavLoop: "",
    youtubeId: "t0jK_gh3Dp4",
    dlcnt: 0,
    artwork: art_yakan,
    bpm: 120,
    loop: false,
    description: "深夜の雲の上をイメージした楽曲です。\n配信・作業用様々な用途にお使いください！\n\nIllust：きゅこ　Kyuko様\n→ Twitter：https://twitter.com/kyyukooo\n→ Piapro：https://piapro.jp/Kyyukooo",
    updateDate: "",
    updateDescription: "",
  },
  {
    id: 24,
    title: "1minute",
    artistIndex: 0,
    audioSrc: onemin,
    categoryIndex: [0, 4, 5],
    date: "23/07/22",
    length: "01:00",
    lengthLoop: "",
    downloadLinkMp3: "1XlAAApaVwd-nmItu2q30XdCupRdYQcRc",
    downloadLinkWav: "1-YX_tF2jfvY8BarDVSWQOGpXW92P3_mH",
    downloadLinkMp3Loop: "",
    downloadLinkWavLoop: "",
    youtubeId: "wPfEn88Xz-c",
    dlcnt: 0,
    artwork: art_onemin,
    bpm: 142,
    loop: false,
    description: "1分ジャストの明るい可愛い曲です！\n配信・作業用様々な用途にお使いください！\n\nIllust：鈴海 色 様\n→ Twitter：https://twitter.com/suzumiiro\n→ Piapro：https://piapro.jp/suzumi_iro",
    updateDate: "",
    updateDescription: "",
  },
  {
    id: 23,
    title: "冷夏",
    artistIndex: 0,
    audioSrc: reika,
    categoryIndex: [1, 2],
    date: "23/07/16",
    length: "03:00",
    lengthLoop: "",
    downloadLinkMp3: "1vKW7fGpiULeRmIsWOyAIaB2d1DHsV9EK",
    downloadLinkWav: "1Mk10o0rVs9P-ls7kOXgIINuWZ-5W9Npb",
    downloadLinkMp3Loop: "",
    downloadLinkWavLoop: "",
    youtubeId: "-ZdzORTFhhw",
    dlcnt: 0,
    artwork: art_reika,
    bpm: 100,
    loop: false,
    description: "涼しげな夏をイメージしたエモーショナルな曲です！\n配信・作業用様々な用途にお使いください！\n\nMovie：朝月みお 様\n→ Twitter：https://twitter.com/ruta5678\n→ Piapro：https://piapro.jp/ruta5678",
    updateDate: "",
    updateDescription: "",
  },
  {
    id: 22,
    title: "rainy",
    artistIndex: 0,
    audioSrc: rainy,
    categoryIndex: [0, 2, 4],
    date: "23/04/30",
    length: "01:28",
    lengthLoop: "",
    downloadLinkMp3: "1SEccOJdXCwUCfIp96OtV6hEql50MX0ad",
    downloadLinkWav: "15M2O8ycj90xVVCC_Z2vSdZJVnFlMNK-I",
    downloadLinkMp3Loop: "",
    downloadLinkWavLoop: "",
    youtubeId: "ovMF0KfjX3Y",
    dlcnt: 0,
    artwork: art_rainy,
    bpm: 162,
    loop: false,
    description: "雨を題材にした可愛い楽曲です！\n配信・作業用様々な用途にお使いください！\n\nIllust：ちゃわんむし 様\n→ Twitter：https://twitter.com/chawanmushi_da\n→ Instagram：https://www.instagram.com/chawanmushi_dayon/",
    updateDate: "",
    updateDescription: "",
  },
  {
    id: 21,
    title: "MEET UP",
    artistIndex: 0,
    audioSrc: meetup,
    categoryIndex: [0, 4],
    date: "23/03/30",
    length: "01:35",
    lengthLoop: "",
    downloadLinkMp3: "1PtUK8YFFMHPijXIjq-0T_k_uTT_ted_a",
    downloadLinkWav: "1pfT4yReXyKQ9VuJIJ6Y-B87hbyvTjqUb",
    downloadLinkMp3Loop: "",
    downloadLinkWavLoop: "",
    youtubeId: "Ftsdv-WFG7k",
    dlcnt: 0,
    artwork: art_meetup,
    bpm: 130,
    loop: false,
    description: "初デートをイメージした可愛い系の楽曲です！\n配信・作業用様々な用途にお使いください！",
    updateDate: "",
    updateDescription: "",
  },
  {
    id: 20,
    title: "monoui offVocal",
    artistIndex: 0,
    audioSrc: monoui_off,
    categoryIndex: [0, 1],
    date: "23/03/06",
    length: "01:27",
    lengthLoop: "",
    downloadLinkMp3: "19bI8FAn9uhcOdA5r02XaN8ilehVaI3XX",
    downloadLinkWav: "1jiNQ85mAPr7ZWctOy8XQd3MY818VFmAU",
    downloadLinkMp3Loop: "",
    downloadLinkWavLoop: "",
    youtubeId: "lmNjm-Wh9nk",
    dlcnt: 0,
    artwork: art_monoui,
    bpm: 105,
    loop: false,
    description: "monoui feat. 可不/kafuのinst（offvocal）バージョンになります。ご自由にお使いください！",
    updateDate: "",
    updateDescription: "",
  },
  {
    id: 19,
    title: "GAME OVER",
    artistIndex: 0,
    audioSrc: gameover,
    categoryIndex: [0, 4, 5],
    date: "23/02/19",
    length: "02:26",
    lengthLoop: "",
    downloadLinkMp3: "1jJV2qlyFIu6wH0erboTRjTv6K2pjqpCr",
    downloadLinkWav: "1F36pQWF0Y6niMk0CapZMg1o90f_qznrQ",
    downloadLinkMp3Loop: "",
    downloadLinkWavLoop: "",
    youtubeId: "W5rf7wSWFn8",
    dlcnt: 0,
    artwork: art_gameover,
    bpm: 150,
    loop: false,
    description: "8bit・Chiptune系のアップテンポな音楽です。配信・作業用様々な用途にお使いください！",
    updateDate: "",
    updateDescription: "",
  },
  {
    id: 18,
    title: "yellow",
    artistIndex: 0,
    audioSrc: yellow,
    categoryIndex: [1],
    date: "23/01/27",
    length: "03:47",
    lengthLoop: "",
    downloadLinkMp3: "173UbSzNFIF_4Rc3O8PxwhuKtGF4d9Dql",
    downloadLinkWav: "1PY7YSZDRmjPKLgrw-5ntLxmlRTunBWyc",
    downloadLinkMp3Loop: "",
    downloadLinkWavLoop: "",
    youtubeId: "HE9d8fd2178",
    dlcnt: 0,
    artwork: art_yellow,
    bpm: 155,
    loop: false,
    description: "心が落ち着く癒し系のBGMです。\nループにも対応しています。配信・作業用様々な用途にお使いください！",
    updateDate: "",
    updateDescription: "",
  },
  {
    id: 17,
    title: "Aquarium",
    artistIndex: 0,
    audioSrc: aquarium,
    categoryIndex: [0, 1, 2],
    date: "22/12/31",
    length: "03:04",
    lengthLoop: "03:12",
    downloadLinkMp3: "1VGgyL_2sVICswp7IQ_DQ1nMyMGcVHh91",
    downloadLinkWav: "1O_XsZ9qqE8RHfgo5icYcUWGTqfqhIygw",
    downloadLinkMp3Loop: "1NC6-lFCn_HT3fnXfI3R-Fs9YMOhBv2vZ",
    downloadLinkWavLoop: "1A6R-J7UzzmBF8T1nQxkhCnal6V5wJTYt",
    youtubeId: "IgXkiBqUZV8",
    dlcnt: 0,
    artwork: art_aquarium,
    bpm: 160,
    loop: true,
    description: "水槽の中をイメージしたおしゃれな楽曲です。\nループにも対応しています。配信・作業用様々な用途にお使いください！",
    updateDate: "",
    updateDescription: "",
  },
  {
    id: 16,
    title: "Donut",
    artistIndex: 0,
    audioSrc: donut,
    categoryIndex: [0, 1, 5],
    date: "22/12/09",
    length: "01:56",
    lengthLoop: "",
    downloadLinkMp3: "16-C7t2xG95jenAf1Xd9Us-mc_vdpMG4K",
    downloadLinkWav: "1nkK2SSpmz3GBmDl6eir7HT4oi_pkq3Ta",
    downloadLinkMp3Loop: "",
    downloadLinkWavLoop: "",
    youtubeId: "XTTwU0TGQRM",
    dlcnt: 0,
    artwork: art_donut,
    bpm: 135,
    loop: false,
    description: "宇宙をイメージしたかわいい楽曲です。宇宙はドーナッツ型？\n配信・作業用様々な用途にお使いください！\n\nIllust：はちろ 様 \n→ Twitter：https://twitter.com/chiro1SK\n→ Piapro : https://piapro.jp/hachiro_860",
    updateDate: "",
    updateDescription: "",
  },
  {
    id: 15,
    title: "Window",
    artistIndex: 0,
    audioSrc: window,
    categoryIndex: [1],
    date: "22/11/17",
    length: "02:02",
    lengthLoop: "",
    downloadLinkMp3: "1PMHzDsBKKzV_7iQZIe3Me04J_1u298Pq",
    downloadLinkWav: "1vSGPDugT90UN1OM8IWfUXW_88WUIYG7m",
    downloadLinkMp3Loop: "",
    downloadLinkWavLoop: "",
    youtubeId: "Q_ALqtkXjHo",
    dlcnt: 0,
    artwork: art_window,
    bpm: 90,
    loop: false,
    description: "車窓からの眺めをイメージしたおしゃれな楽曲です。\n配信・作業用様々な用途にお使いください！",
    updateDate: "",
    updateDescription: "",
  },
  {
    id: 14,
    title: "nemui",
    artistIndex: 0,
    audioSrc: nemui,
    categoryIndex: [0, 3],
    date: "22/10/10",
    length: "02:24",
    lengthLoop: "",
    downloadLinkMp3: "1k45sTc8xioEW0C9_ukIp1er2xZmsWld5",
    downloadLinkWav: "1Kd8oqXXtqrsxVPHwAcnvc1quTqQTqGT7",
    downloadLinkMp3Loop: "",
    downloadLinkWavLoop: "",
    youtubeId: "mq3pYmhU_sA",
    dlcnt: 0,
    artwork: art_nemui,
    bpm: 150,
    loop: false,
    description: "朝をイメージしたかわいい楽曲です。\n配信・作業用様々な用途にお使いください！\n\nIllust：はちろ 様 \n→ Twitter：https://twitter.com/chiro1SK\n→ Piapro : https://piapro.jp/hachiro_860",
    updateDate: "",
    updateDescription: "",
  },
  {
    id: 13,
    title: "Soranaka",
    artistIndex: 0,
    audioSrc: soranaka,
    categoryIndex: [0, 4, 5],
    date: "22/09/23",
    length: "01:31",
    lengthLoop: "",
    downloadLinkMp3: "1xCJkTc7dEEpV4BOkpNj7JLegqfvjbccC",
    downloadLinkWav: "1HsYTp0kruujS2tL8V1V-wwa4TXQArGOF",
    downloadLinkMp3Loop: "",
    downloadLinkWavLoop: "",
    youtubeId: "8nsbLCiNot0",
    dlcnt: 0,
    artwork: art_soranaka,
    bpm: 131,
    loop: false,
    description: "空中をイメージした可愛い楽曲です。\n配信・作業用様々な用途にお使いください！\n\nIllust：丸結ぴか 様 \n→ Twitter：https://twitter.com/chu0pika25\n→ Piapro : https://piapro.jp/pktsgreen333",
    updateDate: "",
    updateDescription: "",
  },
  {
    id: 12,
    title: "BPM150",
    artistIndex: 0,
    audioSrc: bpm150,
    categoryIndex: [0, 4, 5],
    date: "22/09/15",
    length: "02:14",
    lengthLoop: "",
    downloadLinkMp3: "1Jdaji-qF0uIn9APbJIRANILDB11HSHC7",
    downloadLinkWav: "1PmzeL5-hh8oJuxktIVahsZP4RDLisZl6",
    downloadLinkMp3Loop: "",
    downloadLinkWavLoop: "",
    youtubeId: "_DYQaLe69Jc",
    dlcnt: 0,
    artwork: art_bpm150,
    bpm: 150,
    loop: false,
    description: "アップテンポのかわいい楽曲です。\n配信・作業用様々な用途にお使いください！\n\nIllust：ノーコピーライトガール 様",
    updateDate: "",
    updateDescription: "",
  },
  {
    id: 11,
    title: "Airplane",
    artistIndex: 0,
    audioSrc: airplane,
    categoryIndex: [1, 2],
    date: "22/09/09",
    length: "02:12",
    lengthLoop: "",
    downloadLinkMp3: "1TkmaH8cgg_WuzJ1A4c4LS23_TBroZaSv",
    downloadLinkWav: "1EYeFEDr79YOtnycV4jftMfMigHCFjSqx",
    downloadLinkMp3Loop: "",
    downloadLinkWavLoop: "",
    youtubeId: "jRRblgstZvY",
    dlcnt: 0,
    artwork: art_airplane,
    bpm: 83,
    loop: false,
    description: "雨の空港をイメージした可愛い楽曲です。\n配信・作業用様々な用途にお使いください！\n\nIllust：ノーコピーライトガール 様",
    updateDate: "",
    updateDescription: "",
  },
  {
    id: 10,
    title: "ice",
    artistIndex: 0,
    audioSrc: ice,
    categoryIndex: [0, 4],
    date: "22/08/26",
    length: "02:24",
    lengthLoop: "",
    downloadLinkMp3: "1DBe-8uio1_N1wkHedtFx7EmAqFCTS6dQ",
    downloadLinkWav: "162BW1rvZFLpAJAz82mNXMDRG96xUs5MI",
    downloadLinkMp3Loop: "",
    downloadLinkWavLoop: "",
    youtubeId: "c-kopfKrSeI",
    dlcnt: 0,
    artwork: art_ice,
    bpm: "-1",
    loop: false,
    description: "涼しげな可愛い楽曲です。\n配信・作業用様々な用途にお使いください！\n\nIllust：ノーコピーライトガール 様",
    updateDate: "",
    updateDescription: "",
  },
  {
    id: 9,
    title: "OHAYO",
    artistIndex: 0,
    audioSrc: ohayo,
    categoryIndex: [0, 3, 4],
    date: "22/08/17",
    length: "01:50",
    lengthLoop: "",
    downloadLinkMp3: "1UjY_OLL7lG_CbJ3XU7ZSKlbEE0Lt4hpB",
    downloadLinkWav: "1uQf6lpu_uEsamZigFwUkegAEffF5q7FA",
    downloadLinkMp3Loop: "",
    downloadLinkWavLoop: "",
    youtubeId: "1M-f62sTB4Q",
    dlcnt: 0,
    artwork: art_ohayo,
    bpm: "-1",
    loop: false,
    description: "kawaii futurebassを意識した可愛い楽曲です。\n配信・作業用様々な用途にお使いください！\n\nIllust：ノーコピーライトガール 様",
    updateDate: "",
    updateDescription: "",
  },
  {
    id: 8,
    title: "breakfast",
    artistIndex: 0,
    audioSrc: breakfast,
    categoryIndex: [1, 3],
    date: "22/04/09",
    length: "01:27",
    lengthLoop: "",
    downloadLinkMp3: "1iStQHtv3YbXxh3VBUHOmtrru7VHdW835",
    downloadLinkWav: "1qffgcn1cyFKKLspG-0LGCFlEE1laKzrh",
    downloadLinkMp3Loop: "",
    downloadLinkWavLoop: "",
    youtubeId: "bBzlQ2oXkVA",
    dlcnt: 0,
    artwork: art_breakfast,
    bpm: 119,
    loop: false,
    description: "朝をイメージしたおしゃれな楽曲です。\n配信・作業用様々な用途にお使いください！",
    updateDate: "",
    updateDescription: "",
  },
  {
    id: 7,
    title: "ひとりぼっちの雨蛙",
    artistIndex: 0,
    audioSrc: amagaeru,
    categoryIndex: [1, 2],
    date: "22/04/09",
    length: "02:36",
    lengthLoop: "",
    downloadLinkMp3: "17iVUUND9FmW2lmZKZBKlNyBa54vjVkif",
    downloadLinkWav: "12ljd3TdvGxG9b2lOQI3sCthknKoEtyN4",
    downloadLinkMp3Loop: "",
    downloadLinkWavLoop: "",
    youtubeId: "uJAGuYToqj8",
    dlcnt: 0,
    artwork: art_amagaeru,
    bpm: 90,
    loop: false,
    description: "雨の日をイメージしたおしゃれな楽曲です。\n配信・作業用様々な用途にお使いください！",
    updateDate: "",
    updateDescription: "",
  },
  {
    id: 6,
    title: "rojiura",
    artistIndex: 0,
    audioSrc: zihanki,
    categoryIndex: [1],
    date: "22/04/09",
    length: "03:08",
    lengthLoop: "",
    downloadLinkMp3: "1Xg8LKU1fVCXmiZOEVgEFsCfNjgQAPEfo",
    downloadLinkWav: "1eFU35How8_ZZtJB1MErfWP_dkCD8h1FN",
    downloadLinkMp3Loop: "",
    downloadLinkWavLoop: "",
    youtubeId: "mm_MbSnWWCw",
    dlcnt: 0,
    artwork: art_zihanki,
    bpm: "-1",
    loop: false,
    description: "真夜中の路地裏をイメージしたおしゃれな楽曲です。異世界が広がってそう。\n配信・作業用様々な用途にお使いください！",
    updateDate: "",
    updateDescription: "",
  },
  {
    id: 5,
    title: "幾星霜",
    artistIndex: 0,
    audioSrc: ikuseisou,
    categoryIndex: [1],
    date: "22/04/09",
    length: "03:46",
    lengthLoop: "",
    downloadLinkMp3: "1cv2drwCaZVVedGbcTAZ3Gi7dMK70ETHn",
    downloadLinkWav: "1eH8wtxWAWhqXOVDw21iwVy-VLAj82L-M",
    downloadLinkMp3Loop: "",
    downloadLinkWavLoop: "",
    youtubeId: "CaGflTjzNHI",
    dlcnt: 0,
    artwork: art_ikuseisou,
    bpm: "-1",
    loop: false,
    description: "宇宙をイメージしたおしゃれな楽曲です。\n配信・作業用様々な用途にお使いください！",
    updateDate: "",
    updateDescription: "",
  },
  {
    id: 4,
    title: "雨のバス停",
    artistIndex: 0,
    audioSrc: basutei,
    categoryIndex: [1, 2],
    date: "22/04/09",
    length: "03:25",
    lengthLoop: "",
    downloadLinkMp3: "1_ZiURlAxVIt1rNxufUC1kh6RRDOj4K83",
    downloadLinkWav: "1EDtOHtcICKIx6MLpKjioV5o2rpC8GGa1",
    downloadLinkMp3Loop: "",
    downloadLinkWavLoop: "",
    youtubeId: "oKiSnbvUir8",
    dlcnt: 0,
    artwork: art_basutei,
    bpm: "-1",
    loop: false,
    description: "雨の日にバスを待っている場面をイメージしたおしゃれな楽曲です。\n配信・作業用様々な用途にお使いください！",
    updateDate: "",
    updateDescription: "",
  },
  {
    id: 3,
    title: "Universe",
    artistIndex: 0,
    audioSrc: universe,
    categoryIndex: [1],
    date: "22/04/09",
    length: "01:53",
    lengthLoop: "",
    downloadLinkMp3: "17kitxw2txAIn7rx1vELgFMhX6N37ztGt",
    downloadLinkWav: "17wYEUFwiT0eD9vc1w01YkXYhLSSKh76m",
    downloadLinkMp3Loop: "",
    downloadLinkWavLoop: "",
    youtubeId: "",
    dlcnt: 0,
    artwork: art_other,
    bpm: "-1",
    loop: false,
    description: "タイトルに特に意味は無いです...どこか寂しげなアップテンポの曲です。\n配信・作業用様々な用途にお使いください！",
    updateDate: "",
    updateDescription: "",
  },
  {
    id: 2,
    title: "Free!Free!",
    artistIndex: 0,
    audioSrc: freefree,
    categoryIndex: [0, 5],
    date: "22/04/09",
    length: "02:19",
    lengthLoop: "",
    downloadLinkMp3: "12b6uIOoOt9umfL8oAB1TXdAuNrrtDhO1",
    downloadLinkWav: "1I46-ZkbqQjWJRXNHOBM-lDoiAzhNRmLL",
    downloadLinkMp3Loop: "",
    downloadLinkWavLoop: "",
    youtubeId: "",
    dlcnt: 0,
    artwork: art_other,
    bpm: "-1",
    loop: false,
    description: "初期の頃にフリー音源だけで作ったフリーBGMです。\n配信・作業用様々な用途にお使いください！",
    updateDate: "",
    updateDescription: "",
  },
  {
    id: 1,
    title: "bye bye",
    artistIndex: 0,
    audioSrc: bye,
    categoryIndex: [1],
    date: "22/04/09",
    length: "01:18",
    lengthLoop: "",
    downloadLinkMp3: "1A_WWSaJxLilIxtyYDldUQJpwCC1iGDZL",
    downloadLinkWav: "1xl8cZSgmys7hnZrPXf-c0FMxOhwVTLGs",
    downloadLinkMp3Loop: "",
    downloadLinkWavLoop: "",
    youtubeId: "",
    dlcnt: 0,
    artwork: art_other,
    bpm: "-1",
    loop: false,
    description: "沖縄に修学旅行に行ったときにできた曲です。\n配信・作業用様々な用途にお使いください！",
    updateDate: "",
    updateDescription: "",
  },
]

