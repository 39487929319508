export default [
  {
    date: "2023/12/09",
    description: "フリー楽曲「cell feat.可不 offvocal ver」を追加しました。",
    label: "BGM",
    link: "/m/28"
  },
  {
    date: "2023/11/24",
    description: "フリーBGM「夜間飛行」を追加しました。",
    label: "BGM",
    link: "/m/26"
  },
  {
    date: "2023/08/26",
    description: "合計ダウンロード数が5000を突破しました！",
    label: "ダウンロード数",
    link: "/"
  },
  {
    date: "2023/07/22",
    description: "フリーBGM「1minute」を追加しました。",
    label: "BGM",
    link: "/m/25"
  },
  {
    date: "2023/07/16",
    description: "フリーBGM「冷夏」を追加しました。",
    label: "BGM",
    link: "/m/24"
  },
  {
    date: "2023/04/30",
    description: "フリーBGM「rainy」を追加しました。",
    label: "BGM",
    link: "/m/23"
  },
  {
    date: "2023/03/30",
    description: "フリーBGM「MEET UP」を追加しました。",
    label: "BGM",
    link: "/m/22"
  },
  {
    date: "2023/03/06",
    description: "フリーBGM「monoui offvocal」を追加しました。",
    label: "BGM",
    link: "/m/20"
  },
  {
    date: "2023/02/19",
    description: "フリーBGM「GAME OVER」を追加しました。",
    label: "BGM",
    link: "/m/19"
  },
  {
    date: "2023/01/27",
    description: "フリーBGM「yellow」を追加しました。",
    label: "BGM",
    link: "/m/18"
  },
  {
    date: "2022/01/06",
    description: "各楽曲のダウンロードページを開設しました。",
    label: "サイト更新",
    link: "/"
  },
  {
    date: "2022/01/06",
    description: "フリーBGM「Aquarium」を追加しました。",
    label: "BGM",
    link: "/m/17"
  },
  {
    date: "2022/12/09",
    description: "フリーBGM「Donut」を追加しました。",
    label: "BGM",
    link: "/m/16"
  },
  {
    date: "2022/12/07",
    description: "合計ダウンロード数が1000を突破しました。",
    label: "ダウンロード数",
    link: "/"
  },
  {
    date: "2022/11/17",
    description: "フリーBGM「Window」を追加しました。",
    label: "BGM",
    link: "/m/15"
  },
  {
    date: "2022/11/04",
    description: "カテゴリー機能を追加しました。",
    label: "サイト更新",
    link: "/"
  },
  {
    date: "2022/10/10",
    description: "フリーBGM「nemui」を追加しました。",
    label: "BGM",
    link: "/m/14"
  },
  {
    date: "2022/09/23",
    description: "フリーBGM「Soranaka」を追加しました。",
    label: "BGM",
    link: "/m/13"
  },
  {
    date: "2022/09/15",
    description: "フリーBGM「BPM150」を追加しました。",
    label: "BGM",
    link: "/m/12"
  },
  {
    date: "2022/09/09",
    description: "フリーBGM「Airplane」を追加しました。",
    label: "BGM",
    link: "/m/11"
  },
  {
    date: "2022/08/26",
    description: "フリーBGM「ice」を追加しました。",
    label: "BGM",
    link: "/m/10"
  },
  {
    date: "2022/08/17",
    description: "フリーBGM「OHAYO」を追加しました。",
    label: "BGM",
    link: "/m/9"
  },
  {
    date: "2022/04/09",
    description: "フリーBGM「Breakfast」を追加しました。",
    label: "BGM",
    link: "/m/8"
  },
  {
    date: "2022/04/09",
    description: "フリーBGM「ひとりぼっちの雨蛙」を追加しました。",
    label: "BGM",
    link: "/m/7"
  },
  {
    date: "2022/04/06",
    description: "フリーBGMダウンロードサイト moeru music.を開設しました。",
    label: "サイト更新",
    link: "/"
  },
];
