import './NotFound.css';
import React, { useState, useEffect, useRef } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { sendGtagEvent } from './gtag.js';
import headData from "./head-data.json";

const Terms = () => {
  return (
    <React.StrictMode>
      <Helmet>
        <title>{headData.notfound.title}</title>
        <meta name="description" content={headData.notfound.description}/>
        <meta property="og:url" content={headData.og_url} />
        <meta property="og:type" content={headData.og_type} />
        <meta property="og:image" content={headData.og_image} />
        <meta property="og:site_name" content={headData.og_site_name} />
        <meta property="og:description" content={headData.notfound.og_description} />
        <meta property="og:title" content={headData.notfound.og_title} />
        <meta property="twitter:card" content={headData.twitter_card} />
        <meta property="twitter:url" content={headData.twitter_url} />
        <meta property="twitter:creator" content={headData.twitter_creator} />
        <meta property="twitter:site" content={headData.twitter_site+'"'} />
        <meta property="twitter:image" content={headData.twitter_image} />
        <meta property="twitter:title" content={headData.notfound.twitter_title} />
        <meta property="twitter:description" content={headData.notfound.twitter_description} />
      </Helmet>
      <div className="NotFound">
          <header className="header">
            <Header />
          </header>
          <div className="notfound-wrapper">
            <div className="notfound-container">
                <h1>
                    404 Not Found
                </h1>
                <h2>
                    「そこになければないですねー」
                </h2>
                <p>
                  お店の店員さんに商品の場所を聞くのってちょっと緊張しますよね。そんな時のライフハック。「お忙しいところすみません。」この一言を頭に付けるだけ。店員さんもきっと親切に接客してくれますよ。
                  その昔、ダイ〇ーのアルバイトをしていたので、これは断言できます。でも、ない物はないんですわ。
                </p>
                <div className="notfound-btn">
                    <a href="https://drive.google.com/uc?export=download&id=1drruvSgBP5H-0AC7zKt6YdyZ3lW4w-L3" onClick={() => sendGtagEvent("download", "NotFound", "NotFound-mp3")}>
                        MP3
                    </a>
                </div>

            </div>
        </div>

          <Footer />
      </div>
    </React.StrictMode>
  );
}

export default Terms;
