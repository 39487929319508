

export default[
  {
    title: "新着順",
    value: "date"
  },
  {
    title: "人気順",
    value: "dlcnt"
  },
  {
    title: "長さ順",
    value: "length"
  },
  {
    title: "BPM順",
    value: "BPM"
  }
];
